let works = () => {
  let getWorksData = (type) => {
    let menuItem = $("section.works .menu .item");
    for (const key in menuItem) {
      if (Object.hasOwnProperty.call(menuItem, key)) {
        const element = menuItem[key];
        if ($(element).data("id") == type) {
          $(element).addClass("active");
        } else {
          $(element).removeClass("active");
        }
      }
    }
    let mobileMenuItem = $("section.works .mobile-menu .option-box .opt");
    for (const key in mobileMenuItem) {
      if (Object.hasOwnProperty.call(mobileMenuItem, key)) {
        const element = mobileMenuItem[key];
        if ($(element).data("id") == type) {
          $(element).addClass("active");
          $("section.works .mobile-menu .selected").text($(element).text());
        } else {
          $(element).removeClass("active");
        }
      }
    }

    ApiService.get(
      "api/profolios",
      "",
      `type=${type}${
        getCookie("happyVip") ? `&password=${getCookie("happyVip")}` : ""
      }`
    )
      .then(async (res) => {
        if (res.status) {
          $("section.works .main .item").remove();
          res.message.forEach((item) => {
            // console.log(item);
            let el = `
              <a class="item" href="work-detail.html?site_name=${
                item.site_name
              }">
                <div class="pic" style="background-image:url(${
                  item.image_hover
                })">
                  <div class="mask" style="background-image:url(${
                    item.image
                  })"></div>
                  ${
                    item.is_vip
                      ? '<div class="vip"><div class="text">VIP</div></div>'
                      : ""
                  }
                </div>
                <div class="info">
                  <div class="subtitle">${item.sub_title}</div>
                  <div class="title">${item.title}</div>
                  <div class="motto">${item.profolio_type_name}</div>
                </div>
              </a>
            `;
            $("section.works .main").append(el);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let initData = () => {
    ApiService.get("api/home", "", "")
      .then(async (res) => {
        if (res.status) {
          console.log(res.message);
          // profolio_types
          res.message.profolio_types.forEach((item, index) => {
            let el = `
            <div class="item" data-id="${item.profolio_type_id}">
              <p>${item.name}</p>
            </div>
            `;
            $("section.works .menu").append(el);
            let mobileEl = `
            <div class="opt" data-id="${item.profolio_type_id}">${item.name}</div>
            `;
            $("section.works .mobile-menu .option-box").append(mobileEl);
          });
          getWorksData(res.message.profolio_types[0].profolio_type_id);

          $("section.works .menu .item").click(function () {
            getWorksData($(this).data("id"));
          });

          $("section.works .mobile-menu .option-box .opt").click(function () {
            $("section.works .mobile-menu .option-box").hide();
            getWorksData($(this).data("id"));
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  initData();

  $("section.works .mobile-menu .selected").click(function () {
    $("section.works .mobile-menu .option-box").show();
  });

  TweenMax.set(".title-animation .text-animation", {
    y: 60,
    rotation: 10,
    opacity: 0,
  });
  doAnimate(
    new TimelineMax().add(
      TweenMax.staggerTo(
        `section.works .title-animation .text-animation-wrapper .text-animation`,
        0.6,
        {
          opacity: 1,
          rotation: 0,
          y: 0,
        },
        0.05
      )
    ),
    {
      triggerElement: "section.works",
      offset: 0, //指標位移
      reverse: false, //動畫重複 default:true
    },
    "works"
  );
};
