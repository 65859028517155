let vip = () => {
  const postVipApi = (password) => {
    ApiService.post("api/vip", {
      password,
    })
      .then((res) => {
        console.log(res);
        if (res.status) {
          document.cookie = `happyVip=${password}`;
          location.href = "/work.html";
        } else {
          alert("密碼錯誤");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  $("section.vip .btn").on("click", function () {
    const password = $("#password").val();
    postVipApi(password);
  });
};
