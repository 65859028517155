let home = () => {
  let worksOwl = null;
  const scrollPlayer = document.getElementById("scrollAnimate");
  const ww = window.innerWidth;
  const wh = window.innerHeight;
  const logoPlayer = document.getElementById("logoAnimate");

  let getWorksData = (type) => {
    let menuItem = $("section.works .menu .item");
    for (const key in menuItem) {
      if (Object.hasOwnProperty.call(menuItem, key)) {
        const element = menuItem[key];
        if ($(element).data("id") == type) {
          $(element).addClass("active");
        } else {
          $(element).removeClass("active");
        }
      }
    }
    let mobileMenuItem = $("section.works .mobile-menu .option-box .opt");
    for (const key in mobileMenuItem) {
      if (Object.hasOwnProperty.call(mobileMenuItem, key)) {
        const element = mobileMenuItem[key];
        if ($(element).data("id") == type) {
          $(element).addClass("active");
          $("section.works .mobile-menu .selected").text($(element).text());
        } else {
          $(element).removeClass("active");
        }
      }
    }

    ApiService.get(
      "api/profolios",
      "",
      `type=${type}${
        getCookie("happyVip") ? `&password=${getCookie("happyVip")}` : ""
      }`
    )
      .then(async (res) => {
        if (res.status) {
          $("section.works .main .carousel-box").remove();
          $("section.works .main").append(
            '<div class="carousel-box owl-carousel" id="worksCarousel"></div>'
          );
          let num = 0;
          let el = "";
          res.message.forEach((item, index) => {
            if (num == 0) el += '<div class="box">';
            el += `
                <a class="item" href="work-detail.html?site_name=${
                  item.site_name
                }" style="background-image:url(${item.image_hover})">
                  <div class="mask" style="background-image:url(${
                    item.image
                  })"></div>
                  ${
                    item.is_vip
                      ? '<div class="vip"><div class="text">VIP</div></div>'
                      : ""
                  }
                  <div class="info-box">
                    <div class="works-title">${item.title}</div>
                    <div class="sub-title">${item.sub_title}</div>
                  </div>
                </a>
            `;
            if (num == 3 || index + 1 == res.message.length) {
              el += "</div>";
              // console.log(el);
              $("section.works .main .carousel-box").append(el);
              num = 0;
              el = "";
            } else {
              num++;
            }
          });

          if (worksOwl == null) {
            $("section.works .arrow-l").click(function () {
              worksOwl.trigger("prev.owl.carousel");
            });
            $("section.works .arrow-r").click(function () {
              worksOwl.trigger("next.owl.carousel");
            });
          }
          worksOwl = $("#worksCarousel").owlCarousel({
            loop: true,
            margin: 20,
            dots: false,
            smartSpeed: 1500,
            items: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let initData = () => {
    ApiService.get("api/home", "", "")
      .then(async (res) => {
        if (res.status) {
          console.log(res.message);
          if (ww > 768) {
            logoPlayer.load(res.message.animate);
          } else {
            logoPlayer.load(res.message.animate_m);
          }
          $("#bannerLogo img").attr("src", res.message.logo);
          // banner
          let num = res.message.banners.length;
          let bannerProduct = $("section.banner figure.product");
          for (let index = 0; index < bannerProduct.length; index++) {
            bannerProduct
              .eq(index)
              .find("img")
              .attr("src", res.message.banners[(index + num) % num]);
          }
          // profolio_types
          res.message.profolio_types.forEach((item, index) => {
            let el = `
            <div class="item" data-id="${item.profolio_type_id}">
              <p>${item.name}</p>
            </div>
            `;
            $("section.works .menu").append(el);
            let mobileEl = `
            <div class="opt" data-id="${item.profolio_type_id}">${item.name}</div>
            `;
            $("section.works .mobile-menu .option-box").append(mobileEl);
          });
          $("section.works .mobile-menu .selected").text(
            res.message.profolio_types[0].name
          );
          getWorksData(res.message.profolio_types[0].profolio_type_id);

          $("section.works .menu .item").click(function () {
            getWorksData($(this).data("id"));
          });

          $("section.works .mobile-menu .option-box .opt").click(function () {
            $("section.works .mobile-menu .option-box").hide();
            getWorksData($(this).data("id"));
          });

          // socials
          // console.log(res.message.Instagram);
          res.message.Instagram.forEach((item) => {
            let el = `
              <a class="item" href="${item.link}" target="_blank">
                <figure class="phone"><img src="${item.image}"></figure>
                <div class="btn btn-animate">
                  <div class="icon">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.0837 2.83325H9.91681C6.00474 2.83325 2.83337 6.00461 2.83337 9.91669V24.0836C2.83337 27.9956 6.00474 31.167 9.91681 31.167H24.0837C27.9958 31.167 31.1671 27.9956 31.1671 24.0836V9.91669C31.1671 6.00461 27.9958 2.83325 24.0837 2.83325Z" stroke="black" stroke-width="2.83337" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M22.667 16.1078C22.8418 17.2868 22.6404 18.4909 22.0915 19.5489C21.5425 20.6069 20.6739 21.4648 19.6092 22.0007C18.5446 22.5366 17.338 22.7231 16.1613 22.5337C14.9845 22.3444 13.8974 21.7888 13.0545 20.946C12.2117 20.1031 11.6561 19.016 11.4668 17.8392C11.2774 16.6625 11.4639 15.4559 11.9998 14.3913C12.5357 13.3266 13.3936 12.458 14.4516 11.909C15.5096 11.3601 16.7137 11.1587 17.8927 11.3335C19.0954 11.5119 20.2088 12.0723 21.0685 12.932C21.9282 13.7917 22.4886 14.9051 22.667 16.1078Z" stroke="black" stroke-width="2.83337" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M24.792 9.2085H24.8079" stroke="black" stroke-width="2.83337" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <p>${item.name}</p>
                  <span></span>
                </div>
              </a>
            `;
            $("#instagramCarousel").append(el);
          });
          let instagramOwl = $("#instagramCarousel").owlCarousel({
            loop: true,
            margin: 20,
            dots: false,
            smartSpeed: 1500,
            items: 1,
            responsive: {
              768: {
                items: 3,
              },
            },
          });
          $(".instagram .arrow-l").click(function () {
            instagramOwl.trigger("prev.owl.carousel");
          });
          $(".instagram .arrow-r").click(function () {
            instagramOwl.trigger("next.owl.carousel");
          });

          // about
          $("section.about .bg").css(
            "background-image",
            "url(" + res.message.about_image + ")"
          );
          $("section.about .bg-m").css(
            "background-image",
            "url(" + res.message.about_image_m + ")"
          );
          $("section.about p.about-text").html(res.message.about_text);
          // member
          const memberColor = [
            "EA3925",
            "C37C51",
            "ED9FB5",
            "EDDC9F",
            "EC5728",
          ];
          const memberSvg = [
            '<svg viewBox="0 0 358 580" fill=""><path fill-rule="evenodd" clip-rule="evenodd" d="M358 0H0V274.689C39.1092 274.689 122.183 271.517 165.269 227.111C229.644 160.764 318.891 158 358 158V0ZM358 340.296C295.73 339.259 269.76 361.376 239.024 387.55C226.202 398.469 212.551 410.094 195.09 421.039C132.671 460.165 47.6331 466 0 466V580H358V340.296Z" fill=""></path></svg>',
            '<svg viewBox="0 0 358 580" fill=""><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H358V274.689C318.891 274.689 235.817 271.517 192.731 227.111C128.356 160.764 39.1093 158 0 158V0ZM0 340.296V580H358V466C310.367 466 225.329 460.165 162.91 421.039C145.449 410.094 131.798 398.469 118.976 387.55C88.2404 361.376 62.2697 339.259 0 340.296Z" fill=""></path></svg>',
          ];
          res.message.members.forEach((item, index) => {
            // console.log(item);
            let el = `
              <div class="box" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                <div class="content" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                  <figure class="member-pic"><img src="${item.image}"></figure>
                  <div class="mask" style="fill:#${
                    memberColor[(index + 5) % 5]
                  }">
                    ${memberSvg[index % 2]}
                  </div>
                  <div class="name">${item.name}</div>
                  <div class="info">
                    <div class="position">${item.job}</div>
                    <div class="motto">${item.description}</div>
                  </div>
                </div>
              </div>
            `;
            $("#membersCarousel").append(el);
          });

          let memberOwl = $("#membersCarousel").owlCarousel({
            loop: false,
            dots: false,
            smartSpeed: 2000,
            items: 2,
            margin: -0.5,
            // autoWidth: true,
            responsive: {
              768: {
                items: 4,
              },
            },
          });
          $(".member .arrow-l").click(function () {
            memberOwl.trigger("prev.owl.carousel");
          });
          $(".member .arrow-r").click(function () {
            memberOwl.trigger("next.owl.carousel");
          });

          // 動態效果
          logoPlayer.addEventListener("ready", () => {
            logoPlayer.play();
          });
          logoPlayer.addEventListener("complete", () => {
            $(".logo-animation").fadeOut();
            $("#bannerLogo").fadeIn();
            $("header .logo").fadeIn();
            $("header .menu-box").fadeIn();
            $("section.banner .product").fadeIn();
            $("section.banner .scroll").fadeIn();
            $("body").removeClass("hide");
            setTimeout(() => {
              scrollPlayer.play();
            }, 1000);
          });
          $("section.banner .scroll").mouseenter(function () {
            scrollPlayer.stop();
            scrollPlayer.play();
          });
          $(".btn-animate").mouseenter(function () {
            $(this).addClass("mouse-in");
          });
          $(".btn-animate").mouseleave(function () {
            $(this).removeClass("mouse-in");
            $(this).addClass("mouse-out");
            setTimeout(() => {
              $(this).removeClass("mouse-out");
            }, 1000);
          });
          TweenMax.set("section.about .member .box", {
            y: 120,
            opacity: 0,
          });
          TweenMax.set("section.about .member figure.mascot", {
            x: -100,
            y: 100,
            opacity: 0,
          });
          doAnimate(
            new TimelineMax()
              .add(
                TweenMax.staggerTo(
                  "section.about .member .box",
                  0.6,
                  {
                    opacity: 1,
                    y: 0,
                  },
                  0.3
                )
              )
              .add(
                TweenMax.to("section.about .member figure.mascot", 0.6, {
                  opacity: 1,
                  x: 0,
                  y: 0,
                })
              )
              .add(function () {
                memberOwl.trigger("next.owl.carousel");
                setTimeout(() => {
                  memberOwl.trigger("prev.owl.carousel");
                }, 500);
              }),
            {
              triggerElement: "section.about .member",
              offset: -200, //指標位移
              reverse: false, //動畫重複 default:true
            },
            "member"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let logoAnimation = () => {
    for (let i = 0; i <= 125; i++) {
      setTimeout(() => {
        $("section.banner figure.logo img").hide();
        $("section.banner figure.logo img").eq(i).show();
        $("section.banner figure.logo-mobile img").hide();
        $("section.banner figure.logo-mobile img").eq(i).show();
      }, i * 50);
    }
  };

  initData();
  // $("body").addClass("hide");

  // 在resize時，重新取得視窗高度，接著設定banner高度
  $("section.banner").css("height", wh);
  $(window).resize(function () {
    $("section.banner").css("height", window.innerHeight);
  });

  $("section.works .mobile-menu .selected").click(function () {
    $("section.works .mobile-menu .option-box").show();
  });
  TweenMax.set(
    "section.works .title-box,section.works .main,section.about .content,section.about .content p,section.about .member .box,section.instagram",
    {
      y: 120,
      opacity: 0,
    }
  );
  doAnimate(
    new TimelineMax()
      .add(
        TweenMax.to("section.works .title-box", 0, {
          opacity: 1,
          y: 0,
        })
      )
      .add(
        TweenMax.staggerTo(
          `section.works .title-animation .text-animation-wrapper .text-animation`,
          0.6,
          {
            opacity: 1,
            rotation: 0,
            y: 0,
          },
          0.05
        )
      )
      .add(
        TweenMax.to("section.works .main", 0.6, {
          opacity: 1,
          y: 0,
        })
      ),
    {
      triggerElement: "section.works",
      offset: -200, //指標位移
      reverse: false, //動畫重複 default:true
    },
    "works"
  );
  doAnimate(
    new TimelineMax()
      .add(
        TweenMax.to("section.about .content", 0.6, {
          opacity: 1,
          y: 0,
        })
      )
      .add(
        TweenMax.staggerTo(
          `section.about .title-animation .text-animation-wrapper .text-animation`,
          0.6,
          {
            opacity: 1,
            rotation: 0,
            y: 0,
          },
          0.05
        )
      )
      .add(
        TweenMax.to("section.about .content p", 0.6, {
          opacity: 1,
          y: 0,
        })
      ),
    {
      triggerElement: "section.about",
      offset: -200, //指標位移
      reverse: false, //動畫重複 default:true
    },
    "about"
  );
  doAnimate(
    new TimelineMax()
      .add(
        TweenMax.to("section.instagram", 0.6, {
          opacity: 1,
          y: 0,
        })
      )
      .add(
        TweenMax.staggerTo(
          `section.instagram .title-animation .text-animation-wrapper .text-animation`,
          0.6,
          {
            opacity: 1,
            rotation: 0,
            y: 0,
          },
          0.05
        )
      ),
    {
      triggerElement: "section.instagram",
      offset: -200, //指標位移
      reverse: false, //動畫重複 default:true
    },
    "instagram"
  );

  $(window).scroll(function () {
    let scrollTop = $(this).scrollTop();
    let headerH = $("header").innerHeight();
    if (scrollTop > wh - headerH) {
      $("header").addClass("fix");
      $("header").addClass("dark");
    } else {
      $("header").removeClass("fix");
      $("header").removeClass("dark");
    }
  });

  TweenMax.set(".title-animation .text-animation", {
    y: 60,
    rotation: 10,
    opacity: 0,
  });
};
