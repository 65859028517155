let canvas = () => {
  //環境變數
  var updateFPS = 30;
  var showMouse = true;
  var time = 0;
  var bgColor = "#000";
  var ww = 1;
  var wh = 1;

  const imgWidth = location.pathname == "/" ? 55 : 55;
  const imgHeight = location.pathname == "/" ? 46 : 46;

  var imgObject = new Image();
  imgObject.src = `https://test.ha-pp-y.com/images/arrow-canvas.png`;
  // imgObject.src = "https://picsum.photos/110/46";

  //控制
  var controls = {
    value: 0,
  };
  //-----------------------------
  //Vec2
  class Vec2 {
    constructor(x, y) {
      this.x = x;
      this.y = y;
    }
    set(x, y) {
      this.x = x;
      this.y = y;
    }
    move(x, y) {
      this.x += x;
      this.y += y;
    }
    add(v) {
      return new Vec2(this.x + v.x, this.y + v.y);
    }
    sub(v) {
      return new Vec2(this.x - v.x, this.y - v.y);
    }
    mul(s) {
      return new Vec2(this.x * s, this.y * s);
    }
    get length() {
      return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    set length(nv) {
      let temp = this.unit.mul(nv);
      this.set(temp.x, temp.y);
    }
    clone() {
      return new Vec2(this.x, this.y);
    }
    toString() {
      return `(${this.x},${this.y})`;
    }
    equal(v) {
      return this.x == v.x && this.y == v.y;
    }
    get angle() {
      return Math.atan2(this.y, this.x);
    }
    get unit() {
      return this.mul(1 / this.length);
    }
    distance(v2) {
      return v2.sub(this).length;
    }
  }
  //-----------------------------

  var canvas = document.getElementById("arrowCanvas");
  var ctx = canvas.getContext("2d");

  ctx.circle = function (v, r) {
    ctx.arc(v.x, v.y, r, 0, Math.PI * 2);
  };
  ctx.line = function (v1, v2) {
    this.moveTo(v1.x, v1.y);
    this.lineTo(v2.x, v2.y);
  };
  //canvas設定
  function initCanvas() {
    ww = canvas.width = window.innerWidth;
    wh = canvas.height = window.innerHeight;
  }
  initCanvas();
  //邏輯初始化
  function init() {}
  //遊戲邏輯更新
  function update() {
    time++;
  }
  //畫面更新
  function draw() {
    //清空背景
    // // ctx.fillStyle = bgColor;
    // ctx.fillRect(0, 0, ww, wh);
    ww = canvas.width = window.innerWidth;
    wh = canvas.height = window.innerHeight;

    //------------------------------
    //這裡繪製
    if (ww > 768)
      ctx.drawImage(imgObject, mousePos.x, mousePos.y, imgWidth, imgHeight);

    //------------------------------
    //滑鼠
    // ctx.fillStyle = "#f00";
    // ctx.beginPath();
    // ctx.circle(mousePos, 3);
    // ctx.fill();

    // ctx.save();
    // ctx.beginPath();
    // ctx.translate(mousePos.x, mousePos.y);
    // ctx.strokeStyle = "#f00";
    // let len = 20;
    // ctx.line(new Vec2(-len, 0), new Vec2(len, 0));
    // ctx.fillText(mousePos, 10, -10);
    // ctx.stroke();
    // ctx.rotate(Math.PI / 2);
    // ctx.line(new Vec2(-len, 0), new Vec2(len, 0));
    // ctx.stroke();
    // ctx.restore();

    requestAnimationFrame(draw);
  }
  //頁面載入
  function loaded() {
    initCanvas();
    init();
    requestAnimationFrame(draw);
    setInterval(update, 1000 / updateFPS);
  }
  //載入
  // window.addEventListener("load", loaded);
  imgObject.onload = function () {
    // 图像已加载，可以在画布上绘制
    loaded();
  };
  //縮放
  window.addEventListener("resize", initCanvas);

  //滑鼠事件跟紀錄
  var mousePos = new Vec2(0, 0);
  var mousePosDown = new Vec2(0, 0);
  var mousePosUp = new Vec2(0, 0);
  console.log(location.href);
  const el =
    location.pathname == "/"
      ? document.querySelector("section.about .member")
      : document.querySelector("section.picture-collection .pic-carousel");
  el.addEventListener("mousemove", mousemove);
  el.addEventListener("mouseup", mouseup);
  el.addEventListener("mousedown", mousedown);

  function mousemove(evt) {
    mousePos.set(evt.x, evt.y);
    // console.log(mousePos);
  }
  function mouseup(evt) {
    console.log(":", evt);
    mousePos.set(evt.x, evt.y);
    mousePosUp = mousePos.clone();
    console.log(mousePosUp);
  }
  function mousedown(evt) {
    mousePos.set(evt.x, evt.y);
    mousePosDown = mousePos.clone();
    console.log(mousePosDown);
  }
};
