let contact = () => {
  let initData = () => {
    ApiService.get("api/contact", "", "")
      .then(async (res) => {
        if (res.status) {
          // $("section.contact .title span").html(res.message.title);
          $("section.contact .intro").html(res.message.content);
          $("section.contact a.link p").html(res.message.link_title);
          $("section.contact a.link").attr("href", res.message.link);
          doAnimate(
            new TimelineMax().add(
              TweenMax.staggerTo(
                `section.contact .title-animation .text-animation-wrapper .text-animation`,
                0.6,
                {
                  opacity: 1,
                  rotation: 0,
                  y: 0,
                },
                0.05
              )
            ),
            {
              triggerElement: "section.contact",
              offset: 0, //指標位移
              reverse: false, //動畫重複 default:true
            },
            "contact"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  initData();

  $(".btn-animate").mouseenter(function () {
    $(this).addClass("mouse-in");
  });
  $(".btn-animate").mouseleave(function () {
    $(this).removeClass("mouse-in");
    $(this).addClass("mouse-out");
    setTimeout(() => {
      $(this).removeClass("mouse-out");
    }, 1000);
  });
  TweenMax.set(".title-animation .text-animation", {
    y: 60,
    rotation: 10,
    opacity: 0,
  });
};
