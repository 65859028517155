let worksDetail = () => {
  var app = new Vue({
    el: "#app",
    data: {
      initData: {
        index: {},
        highlights: { images: [] },
        awards: {},
        credits: { credits: [] },
        slider: {},
      },
    },
    created() {
      ApiService.get(
        "api/profolio",
        "",
        `id=${getUrlQuery("site_name")}${
          getCookie("happyVip") ? `&password=${getCookie("happyVip")}` : ""
        }`
      )
        .then(async (res) => {
          if (res.status) {
            this.initData = res.message;
            console.log(this.initData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    computed: {
      highlightsImages() {
        let highlightData = null;
        let imgArr = [];
        let tempArr = [];
        for (const key in this.initData.profoliodetails) {
          if (Object.hasOwnProperty.call(this.initData.profoliodetails, key)) {
            const element = this.initData.profoliodetails[key];
            if (element.template == "highlights") {
              highlightData = element;
            }
          }
        }
        if (highlightData == null) return [];
        highlightData.images.forEach((element, index) => {
          tempArr.push(element);
          if (index % 2 || highlightData.images.length - 1 == index) {
            imgArr.push(tempArr);
            tempArr = [];
          }
        });
        highlightData.images.forEach((element, index) => {
          tempArr.push(element);
          if (index % 2 || highlightData.images.length - 1 == index) {
            imgArr.push(tempArr);
            tempArr = [];
          }
        });
        return imgArr || [];
      },
      creditsLeft() {
        let tempArr = [];
        this.initData.credits.credits.forEach((element, index) => {
          console.log(element.credit_position);
          if (element.credit_position == 0) {
            tempArr.push(element);
          }
        });
        return tempArr;
      },
      creditsRight() {
        let tempArr = [];
        this.initData.credits.credits.forEach((element, index) => {
          console.log(element.credit_position);
          if (element.credit_position == 1) {
            tempArr.push(element);
          }
        });
        return tempArr;
      },
    },
    mounted() {
      $("section").fadeIn();
      TweenMax.set("section.award figure.pic", {
        x: -30,
        y: 50,
        opacity: 0,
      });
      doAnimate(
        new TimelineMax().add(
          TweenMax.to("section.award figure.pic", 0.6, {
            opacity: 1,
            x: 0,
            y: 0,
          })
        ),
        {
          triggerElement: "section.award",
          offset: 0, //指標位移
          reverse: false, //動畫重複 default:true
        },
        "award"
      );
      $(window).scroll(function () {
        let scrollTop = $(this).scrollTop();
        let headerH = $("header").innerHeight();
        console.log(window.innerHeight);
        console.log(scrollTop);
        if (scrollTop > window.innerHeight - headerH) {
          $("header").addClass("fix");
          $("header").addClass("dark");
        } else {
          $("header").removeClass("fix");
          $("header").removeClass("dark");
        }
      });
    },
    filters: {},
    methods: {
      titleFilter(val, br = true) {
        let temp = val || "";
        const characters = temp.split("");
        let string = "<span>";
        characters.forEach((item) => {
          if (item != " ") {
            string += `<span class="text-animation-wrapper"><span class="text-animation">${item}</span></span>`;
          } else {
            if (br) {
              string += `</br>`;
            } else {
              string += `</span> <span>`;
            }
          }
        });
        string += "</span>";
        return string;
      },
    },
    watch: {
      highlightsImages(val) {
        setTimeout(() => {
          let picOwl = $("#picCarousel").owlCarousel({
            loop: true,
            dots: false,
            margin: 30,
            smartSpeed: 1500,
            items: 1.5,
            center: true,
            responsive: {
              768: {
                items: 3,
                center: false,
              },
            },
          });
          $(".arrow-l").click(function () {
            picOwl.trigger("prev.owl.carousel");
          });
          $(".arrow-r").click(function () {
            picOwl.trigger("next.owl.carousel");
          });
        }, 1);
      },
      "initData.slider"(val) {
        setTimeout(() => {
          let recommendOwl = $("#recommendCarousel").owlCarousel({
            loop: true,
            margin: 20,
            dots: false,
            smartSpeed: 1500,
            items: 1,
            responsive: {
              768: {
                items: 3,
              },
            },
          });
          $(".recommend .arrow-l").click(function () {
            recommendOwl.trigger("prev.owl.carousel");
          });
          $(".recommend .arrow-r").click(function () {
            recommendOwl.trigger("next.owl.carousel");
          });
        }, 1);
      },
      "initData.profoliodetails"(val) {
        setTimeout(() => {
          $("section").fadeIn();
        }, 1);
      },
      initData(val) {
        setTimeout(() => {
          gsap.registerPlugin(ScrollTrigger);

          gsap.utils.toArray("[data-fade-animation]").forEach((el) => {
            gsap.from(el, {
              //選取物件
              scrollTrigger: {
                trigger: el,
                start: "top 80%", //當物件抵達視窗30%高度時開始
                scrub: false, //動畫隨著捲動推移
                // toggleActions: "restart pause reverse pause", //在視窗外不移動，往上捲時動畫倒轉
              }, //scrollTrigger特效
              opacity: 0, //從透明淡入
              y: 30,
              duration: 1.0, //維持1.5s
            });
          });

          TweenMax.set(".title-animation .text-animation", {
            y: 60,
            rotation: 10,
            opacity: 0,
          });
          let animation = $(".title-animation");
          for (let i = 0; i < animation.length; i++) {
            console.log($(".title-animation").eq(i)[0].classList[2]);
            doAnimate(
              new TimelineMax().add(
                TweenMax.staggerTo(
                  `.${
                    $(".title-animation").eq(i)[0].classList[2]
                  } .text-animation-wrapper .text-animation`,
                  0.6,
                  {
                    opacity: 1,
                    rotation: 0,
                    y: 0,
                  },
                  0.05
                )
              ),
              {
                triggerElement: `.${
                  $(".title-animation").eq(i)[0].classList[2]
                }`,
                offset: -300, //指標位移
                reverse: false, //動畫重複 default:true
              },
              $(".title-animation").eq(i)[0].classList[2]
            );
          }
        }, 1);
      },
    },
  });
};
