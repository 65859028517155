const apiDomain = "https://test.ha-pp-y.com";

const ApiService = {
  get(resource, slug = "", params) {
    return new Promise((resolve, reject) => {
      const route = slug ? `${resource}/${slug}` : `${resource}`;
      fetch(`${apiDomain}/${route}?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  post(resource, params) {
    return new Promise((resolve, reject) => {
      fetch(`${apiDomain}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  postBlob(resource, params) {
    let formData = new FormData();
    formData.append("file", params);
    return new Promise((resolve, reject) => {
      fetch(`${apiDomain}/${resource}`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

let controller = new ScrollMagic.Controller();
let isMobile = () => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};

const getUrlQuery = (key) => {
  const url = location.href;
  if (url.indexOf("?") != -1) {
    const ary = url.split("?")[1].split("&");
    for (let i = 0; i <= ary.length - 1; i++) {
      if (ary[i].split("=")[0] == key) {
        return ary[i].split("=")[1];
      }
    }
  }
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

let doAnimate = (tween, Scene, indicator) => {
  return (
    new ScrollMagic.Scene(Scene)
      .setTween(tween)
      // .addIndicators({ name: indicator }) // 指標顯示
      .addTo(controller)
  );
};
let initData = () => {
  ApiService.get("api/home", "", "")
    .then(async (res) => {
      if (res.status) {
        // console.log(res.message);
        $("footer .instagram").attr("href", res.message.Official[0].link);
        $("footer .build").attr("href", res.message["Dream Build"][0].link);
        $("footer .shop").attr("href", res.message.Shop[0].link);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  ApiService.get("api/contact", "", "")
    .then(async (res) => {
      if (res.status) {
        $("footer .email").html(res.message.link_title);
        $("footer .email").attr("href", res.message.link);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
let all = () => {
  initData();

  $("header .mobile-menu").on("click", function () {
    $("header .menu").fadeIn();
  });

  $("header .menu .close").on("click", function () {
    $("header .menu").fadeOut();
  });

  let pathname = location.pathname;

  if (pathname != "/") {
    $("header .logo").fadeIn();
    $("header .menu-box").fadeIn();
  }

  $("header a.item").removeClass("active");
  if (pathname == "/work.html" || pathname == "/work-detail.html") {
    $("header a.item").eq(0).addClass("active");
  } else if (pathname == "/contact.html") {
    $("header a.item").eq(1).addClass("active");
  } else if (pathname == "/vip.html") {
    $("header a.item").eq(2).addClass("active");
  }
};

all();
